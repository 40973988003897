import React from "react";

import { graphql } from "gatsby";

import { 
    ChallengeListing,
    ErrorUI,
    getChallengeStatus,
    Layout
} from "../components";

const ChallengesIndex = ({ data }) => {

    const challenges = data?.allWpChallenge?.nodes ?? [];
    
    const runningChallenges = challenges.filter((challenge) => {
        let status = getChallengeStatus(challenge);
        return status === 'running';
    });

    const overChallenges = challenges.filter((challenge) => {
        let status = getChallengeStatus(challenge);
        return status === 'over';
    });


    if ( runningChallenges.length ) {
        const challenge = runningChallenges.length ? runningChallenges[0] : null;
        const title = challenge?.title ?? "";
        return (
            <Layout title={`Challenge - ${title}`}>
                <ChallengeListing challenge={challenge} />
            </Layout>
        )
    }

    if ( overChallenges.length ) {
        const challenge = overChallenges.length ? overChallenges[0] : null;
        const title = challenge?.title ?? "";
        return (
            <Layout title={`Challenge - ${title}`}>
                <ChallengeListing challenge={challenge} />
            </Layout>
        )
    }

    return (
        <Layout
            title={"Challenges"}
        >
            <ErrorUI 
                content={"There are no challenges at the moment. Please try again."}
                title={"No Challenges"}
            />
        </Layout>
    );
}
export default ChallengesIndex;

export const pageQuery = graphql`
    query {
        allWpChallenge(
            sort: { fields: [date], order: DESC }
            limit: 100
            skip: 0
        ) {
            nodes {
                id
                databaseId
                title
                featuredImage {
                    node {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    height: 400
                                    placeholder: TRACED_SVG
                                )
                            }
                        }
                    }
                }
                slug
                questionVideo
                answerVideo
                startDatetime
                endDatetime
                rewardImage {
                    altText
                    localFile {
                        icon: childImageSharp {
                            gatsbyImageData(height: 32)
                        }
                        small: childImageSharp {
                            gatsbyImageData(height: 60)
                        }
                        medium: childImageSharp {
                            gatsbyImageData(width: 200)
                        }
                    }
                }
                rewardName
                rewardSpots
                questionFormFields
                betWindows
                bannerChatBottomDestination
                bannerChatBottomImage {
                    altText
                    localFile {
                        small: childImageSharp {
                            gatsbyImageData(height: 60)
                        }
                        medium: childImageSharp {
                            gatsbyImageData(width: 400)
                        }
                    }
                }
                bannerChatTopDestination
                bannerChatTopImage {
                    altText
                    localFile {
                        small: childImageSharp {
                            gatsbyImageData(height: 60)
                        }
                        medium: childImageSharp {
                            gatsbyImageData(width: 400)
                        }
                    }
                }
                adPreRollId
                adPreRollHeadline
                adPreRollDisplayUrl
                adPreRollFinalUrl
                adEndRollId
                adEndRollHeadline
                adEndRollDisplayUrl
                adEndRollFinalUrl
                adReplayId
                adReplayHeadline
                adReplayDisplayUrl
                adReplayFinalUrl
            }
        }
    }
`
